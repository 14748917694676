
.hero-wrapper{
    background: #131110;
    padding: 9rem 25rem;
    max-width: 100%;
    height: 50rem;
    /* z-index: 1; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.display-image img{
    width: 25rem;
    border: solid rgb(58, 58, 58) 5px;
    border-radius: 50rem 50rem 0px 0px;
}
.left-section{
    color: white;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.mainHeading{
    padding-top: 2.5rem;
    font-size: xx-large;
    font-weight: bolder;
    z-index: 1;
    font-weight: 600;
    font-size: 3.8rem;
    line-height: 4rem;
}
.circle{
    background: linear-gradient(270deg, #ffb978 0%, #ff922d 100%);
    width: 50px;
    height: 50px; 
    border-radius: 100px;
    position: absolute;
    top: 180px;
    left: 630px;
    /* z-index: -1; */
    
}
.bg-circle{
    background-color:rgb(197, 197, 197);
    width: 20rem;
    height: 20rem; 
    border-radius: 20rem;
    position: absolute;
    top: 1px;
    left: 0px;
    z-index: 1;
    box-shadow: black 5px; 
    filter: blur(7rem);
}

.search-bar{
    display: flex;
    flex-direction: row;
}


.input{
    padding: 1.5rem 8rem; 
    border: none;
    border-radius: 5px;
    color: black;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
    /* /* overflow: hidden;// */

}

.search-btn {
    --clr-font-main: hsla(0 0% 20% / 100);
    --btn-bg-1: hsla(194 100% 69% / 1);
    --btn-bg-2: hsla(217 100% 56% / 1);
    --btn-bg-color: hsla(360 100% 100% / 1);
    --radii: 0em 0.5em 0.5em 0em;
    cursor: pointer;
    padding: 0.9em 1.4em;
    min-width: 120px;
    min-height: 44px;
    font-size: var(--size, 1rem);
    font-family: "Segoe UI", system-ui, sans-serif;
    font-weight: 500;
    transition: 0.8s;
    background-size: 280% auto;
    background-image: linear-gradient(325deg, var(--btn-bg-2) 0%, var(--btn-bg-1) 55%, var(--btn-bg-2) 90%);
    border: none;
    border-radius: var(--radii);
    color: var(--btn-bg-color);
    box-shadow: 0px 0px 20px rgba(71, 184, 255, 0.5), 0px 5px 5px -1px rgba(58, 125, 233, 0.25), inset 4px 4px 8px rgba(175, 230, 255, 0.5), inset -4px -4px 8px rgba(19, 95, 216, 0.35);
  }
  
  .search-btn:hover {
    background-position: right top;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .search-btn {
      transition: linear;
    }
  }
  
  .search-bar{
    background-color: white;
    /* padding: 0.5rem; */
    border-radius: 10px;

  }

  .icon{
    padding: 10px;
    border-radius: 5px 0px 0px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: hsla(194 100% 69% / 1);
  }

  .img-tags{
    width: 100%;
    padding: 1rem 3rem;
    display: flex;
    flex-direction: row;
    border: 5px solid white;
    border-radius: 10px;
    /* flex-wrap: wrap; */
    justify-content: space-evenly;
  }
  .img-tags img{
    width: 10rem;
    height: 6rem;
  }
  
.choices{
    padding: 0rem 3rem;
    color: orange;
    font-size: 1.5rem;
    font-weight: 600;
}
.residencies{
    color: rgb(8, 8, 127);
    font-weight: 700;
}
.properties{
    width: 100px;
    height: 100px;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.choices{
  padding: 2rem 20rem;
}