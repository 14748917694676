.right > img{
    width: 30rem;
    height: 40rem;
    border: solid 5px rgba(186, 186, 186, 0.78);
    border-radius: 50rem 50rem 0px 0px;
}

.contact{
    display: flex;
    margin: 5rem 1rem;
    padding: 0rem 25rem;
    justify-content: space-between;
}

.title > span{
    font-size:x-large;
    font-weight: bold;
    color: orange;
}
.title > h1{
    font-size:xx-large;
    font-weight:bolder;
    color: rgb(49, 44, 123);
}
.title > p{

    color: #8c8b8b;
    font-size: 1.5rem;
}
.call>span{
    font-size: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: blue;

}

.call>h1{
    /* font-size: 3rem; */
    color: #1f3e72;
}

.call{
    width: 18rem;
    padding: 0rem 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 4px solid #015baf;
    border-radius: 10px 10px 0px 0px;
    margin-top: 1rem;
    /* margin-bottom: 1rem; */
}
.call-button{
    width: 18rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

  .call-button {
    font-size: 18px;
    letter-spacing: 2px;
    text-transform: uppercase;
    display: inline-block;
    text-align: center;
    font-weight: bold;
    padding: 0.7em 2em;
    border: 3px solid #0084ff;
    border-radius: 2px;
    position: relative;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.1);
    color: #0084ff;
    text-decoration: none;
    transition: 0.3s ease all;
    z-index: 1;
  }
  
  .call-button:before {
    transition: 0.5s all ease;
    position: absolute;
    top: 0;
    left: 50%;
    right: 50%;
    bottom: 0;
    opacity: 0;
    content: '';
    background-color: #0084ff;
    z-index: -1;
  }
  
  .call-button:hover, .call-button:focus {
    color: white;
  }
  
  .call-button:hover:before, .call-button:focus:before {
    transition: 0.5s all ease;
    left: 0;
    right: 0;
    opacity: 1;
  }
  
  .call-button:active {
    transform: scale(0.9);
  }