.left-section > img{
    width: 30rem;
    height: 40rem;
    border: solid 5px rgba(186, 186, 186, 0.78);
    border-radius: 50rem 50rem 0px 0px;
}

.value-container{
    margin: 5rem 1rem;
    padding: 0rem 25rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 8rem;
}

.right-section{
display: flex;
flex-direction: column;
gap: 1rem;

}

.right-section>h2{
    font-size: x-large;
    font-weight: bolder;
    color: orange;
}

.right-section>span{
    /* font-size: x-large; */
    font-weight: 600;
    color: rgb(0, 0, 0);
}

.right-section>h1{
    font-size: x-large;
    font-weight: bolder;
    color: rgb(43, 43, 145);
}

.info-head{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    font-weight: 600;
    color: #1f3e72;
}

.info-head > span{
    font-size:larger;
    font-weight: 700;
}
.information{
    padding: 1rem 1rem;
   /* border: 5px solid black; */
   width: 35rem;
   height:13rem;
   border-radius: 10px;
   box-shadow: 4px 5px 10px 2px #9bb9da;
}