.r-card > img{
    /* padding: 0rem 2rem; */
    margin-top: 2rem;
    background-color: rgb(255, 255, 255);
    width: 15rem;
    height: 15rem;
    /* border: solid black 5px; */
    /* z-index: 1; */

}

.s-container{
    /* background-color: rgb(61, 122, 166); */
    padding: 0rem 20rem;
    /* border: solid black 5px; */
    cursor: pointer;
}
.card-body{
    display: flex;
    flex-direction: column;   
}
.slick-slide>div{
    padding: 0rem 3rem;
    margin: 0rem 4rem;
}
.r-card :hover{
    transform: scale(1.1);
    transition: 0.5s;
}

.price{
    margin: 1rem 0rem;
    color: rgb(218, 142, 2);
}

