* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  
  /* background: #131110; */
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.links {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  list-style: none;
  color: white;
}
.links a {
  text-decoration: none;
  color: white;
  opacity: 80%;
}

.links a:hover{
  opacity: 100%;
  transform: scale(1.1);
  transition: all 300ms ease-in;

}

.header {
  width: 100%;
  height: 100px;
  background: #131110;
  padding: 20px 15rem;
  position: fixed;
  display: flex;
  justify-content: space-between;
  background-color: var(#1564b2);
  z-index: 1;
}
/* .register-button {
  padding: 10px 20px;
  background: #1564b2;
  color: aliceblue;
  border-radius: 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.register-button:hover{
  transition: all 300ms ease-in;
  transform: scale(1.1);
} */
.register-button {
  text-decoration: none;
  position: relative;
  border: none;
  font-size: 14px;
  font-family: inherit;
  cursor: pointer;
  color: #fff;
  width: 9em;
  height: 3em;
  line-height: 2em;
  text-align: center;
  background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
  background-size: 300%;
  border-radius: 30px;
  z-index: 1;
}

.register-button:hover {
  animation: ani 8s linear infinite;
  border: none;
}

@keyframes ani {
  0% {
    background-position: 0%;
  }

  100% {
    background-position: 400%;
  }
}

.register-button:before {
  content: "";
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  z-index: -1;
  background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
  background-size: 400%;
  border-radius: 35px;
  transition: 1s;
}

.register-button:hover::before {
  filter: blur(20px);
}

.register-button:active {
  background: linear-gradient(32deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
}
